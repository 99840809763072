<template>
  <div>
    <div class="text-muted text-center">Ventas no Completadas</div>
    <div v-if="!isLoading" class="text-center mt-2">
      <p>$ {{ formatPrice(data.profit) }}</p>
    </div>
    <div v-if="isLoading" class="text-center mt-2">
      <div class="spinner-border text-primary">
      </div>
    </div>
  </div>
</template>

<script>
import Resource from "@/api/resource";

const resource = new Resource("dashboard");

export default {
  name: "SalesNotCompleted",
  components: {},
  data() {
    return {
      isLoading: false,
      loaded: false,
      data: {
        profit: 0,
      },
    };
  },
  watch: {
    "$store.state.dashboard.filters": function (newValue, oldValue) {
      if (newValue) {
        this.getData();
      }
    },
  },
  created() {
    if (!this.loaded) {
      this.getData();
    }
  },
  methods: {
    async getData() {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          const { dateFrom, dateTo, tree } = this.$store.state.dashboard.filters;
          const result = await resource.get(`salesReport?fromLastLiquidation=0&profit=0&status=pending&tree=${tree ? 1 : 0}&dateFrom=${dateFrom}&dateTo=${dateTo}`);
          if (result.status == 200) {
            this.data.profit = result.data;
            this.loaded = true;
          } else {
            console.log(result);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
