<template>
  <div>
    <div class="text-muted text-center">Ventas Diarias</div>
    <div style="height: 335px">
      <CChartLine :datasets="defaultDatasets" :labels="labels" class="mt-4" v-if="data.length > 0 && !isLoading" />
      <div v-if="data.length == 0 && !isLoading" class="text-center mt-4">
        <p>Sin datos para mostrar</p>
      </div>
      <div v-if="isLoading" class="text-center mt-4">
        <div class="spinner-border text-primary">
          <span class="sr-only">Cargando...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CChartLine } from "@coreui/vue-chartjs";
import Resource from "@/api/resource";

const resource = new Resource("dashboard");

export default {
  name: "DailySales",
  components: {
    CChartLine,
  },
  data() {
    return {
      isLoading: false,
      loaded: false,
      data: [],
    };
  },
  computed: {
    defaultDatasets() {
      const sale = {
        label: 'Ventas',
        backgroundColor: 'rgb(8,125,233,0.5)',
        data: this.data.map(item => item.sale),
      };
      const profit = {
        label: 'Ganancias',
        backgroundColor: 'rgb(8,233,74,0.5)',
        data: this.data.map(item => item.profit),
      };
      return [
        sale,
        profit
      ]
    },
    labels() {
      return this.data.map(item => item.date);
    },
  },
  watch: {
    "$store.state.dashboard.filters": function (newValue, oldValue) {
      if (newValue) {
        this.getData();
      }
    },
  },
  created() {
    if (!this.loaded) {
      this.getData();
    }
  },
  methods: {
    async getData() {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          const { dateFrom, dateTo, tree } = this.$store.state.dashboard.filters;
          const result = await resource.get(`dailySales?tree=${tree ? 1 : 0}&dateFrom=${dateFrom}&dateTo=${dateTo}`);
          if (result.status == 200) {
            this.data = result.data.map(item => {
              item.profit = this.formatPrice(item.profit);
              item.sale = this.formatPrice(item.sale);
              return item; 
            });
            this.loaded = true;
          } else {
            console.log(result);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    formatPrice(value) {
      return Math.round(value * 100) / 100
    },
  },
};
</script>
