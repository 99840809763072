<template>
  <CCol v-if="show" sm="24">
    <CCard>
      <CCardBody>
        <CRow>
          <CCol xs="12" md="6">
            <radial-progress-bar
              :diameter="250"
              :completed-steps="selectedBadge?parseFloat(currentAmount<selectedBadge.value?currentAmount:selectedBadge.value):0"
              :total-steps="selectedBadge?parseFloat(selectedBadge.value):0"
              startColor="#0094A1"
              endColor="#00454E"
            >
              <p v-if="currentAmount<(selectedBadge?selectedBadge.value:0)" style="text-align: center;">Siguiente medalla: <br/>{{ selectedBadge?selectedBadge.name:'' }}</p>
              <p v-if="currentAmount<(selectedBadge?selectedBadge.value:0)" style="text-align: center;">${{ amountForNextMedal }} más para tu próxima medalla</p>
              <p v-else style="text-align: center;">Felicitaciones!!!<br/>Ya conseguiste la medalla</p>
              <p>Progreso mensual: ${{ currentAmount }}</p>
            </radial-progress-bar>
          </CCol>
          <CCol xs="12" md="6">
            Próximas medallas:<br/><br/>
            <ul>
              <div v-for="badge in badges" :key="'badge'+badge.id">
                <li @click="selectBadge(badge)">{{ badge.name }} <span>{{ badge.value }}</span></li>
                <p>{{ badge.label }}</p>
              </div>
            </ul>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CCol>
</template>
  
<script>
import RadialProgressBar from 'vue-radial-progress'
import Resource from "@/api/resource";

const resource = new Resource("dashboard");
export default {
  name: 'ProgressView',
  data() {
    return {
      selectedBadge: null,
      badges: [],
      currentAmount: 0,
      show: false,
    };
  },
  components: {
    RadialProgressBar
  },
  mounted(){
    this.load();
  },
  computed: {
    amountForNextMedal() {
      return parseFloat(this.selectedBadge?this.selectedBadge.value:0) - parseFloat(this.selectedBadge?(this.currentAmount<this.selectedBadge.value?this.currentAmount:this.selectedBadge.value):0);
    },
  },
  methods: {
    async load() {
      await resource.get('salesByMonth').then(response => {
        this.currentAmount = parseFloat(response.data[0].sum).toFixed(2);
        this.currentAmount = isNaN(this.currentAmount) ? 0 : this.currentAmount;
        if(this.$store.state.user.role == "Distribuidor"){
          this.show=true;
        }
        this.show=true;
      }).catch(error => {
        this.show=false;
      }).finally(() => {

      });
      await resource.get('nextAwards').then(response => {
        this.badges = response.data.data;
        this.selectBadge();
      }).catch(error => {
        this.show=false;
      }).finally(() => {

      });
    },
    selectBadge(badge) {
      this.selectedBadge = badge ? badge : this.findClosestBadge();
      this.selectedBadge.value = parseFloat(this.selectedBadge.value);
    },
    findClosestBadge() {
      let closestBadge = null;
      let closestDifference = Infinity;

      for (const badge of this.badges) {
        const badgeValue = badge.value;
        const difference = Math.abs(this.currentAmount - badgeValue);

        if (difference < closestDifference) {
          closestBadge = badge;
          closestDifference = difference;
        }
      }

      return closestBadge;
    },
  },
};
</script>
  
<style>
.progress-bar {
  height: 20px;
  background-color: #ccc;
}

.goals {
  margin-bottom: 10px;
}
</style>
  