<template>
  <div>
    <div class="text-muted text-center">Medallas</div>
    <div>
      <vue-horizontal-timeline :items="data" class="mt-4" v-if="data.length > 0 && !isLoading" />
      <div v-if="data.length == 0 && !isLoading" class="text-center mt-4">
        <p>Sin datos para mostrar</p>
      </div>
      <div v-if="isLoading" class="text-center mt-4">
        <div class="spinner-border text-primary">
          <span class="sr-only">Cargando...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueHorizontalTimeline } from "vue-horizontal-timeline";
import Resource from "@/api/resource";

const resource = new Resource("dashboard");

export default {
  name: "BadgeTimeline",
  components: {
    VueHorizontalTimeline,
  },
  data() {
    return {
      isLoading: false,
      loaded: false,
      data: [],
    };
  },
  watch: {
    "$store.state.dashboard.filters": function (newValue, oldValue) {
      if (newValue) {
        this.getData();
      }
    },
  },
  created() {
    if (!this.loaded) {
      this.getData();
    }
  },
  methods: {
    async getData() {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          const { dateFrom, dateTo } = this.$store.state.dashboard.filters;
          const result = await resource.get(`badges?dateFrom=${dateFrom}&dateTo=${dateTo}`);
          if (result.status == 200) {
            this.data = result.data;
            this.loaded = true;
          } else {
            console.log(result);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
