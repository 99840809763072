<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow class="justify-content-center">
          <CCol sm="12" class="mb-4">
            <div class="text-muted text-center">Filtros</div>
          </CCol>
          <CCol sm="12" md="3">
            <CInput type="date" label="Fecha desde" v-model="filters.dateFrom" :max="maxDate" />
          </CCol>
          <CCol sm="12" md="3">
            <CInput type="date" label="Fecha hasta" v-model="filters.dateTo" :min="minDate" :max="maxDate" />
          </CCol>
          <CCol sm="12" md="3">
            <p>Arbol</p>
            <CSwitch color="primary" name="tree" :checked.sync="filters.tree" />
          </CCol>
          <CCol sm="12" class="text-right">
            <CButton class="float-right ml-2" color="success" @click="update" v-if="!loading">
              Filtrar
            </CButton>
            <CButton class="float-right ml-2" color="info" @click="excel" v-if="!loading">
              EXCEL
            </CButton>
            <div class="text-right" v-if="loading">
              <CSpinner variant="grow" size="lg" />
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CRow class="justify-content-center">
      <ProgressView />
      <CCol sm="12">
        <CCard>
          <CCardBody>
            <BadgeTimeline />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="12" md="12">
        <CRow class="justify-content-center">
          <CCol sm="12" md="3">
            <CCard>
              <CCardBody>
                <AccumulatedSale />
              </CCardBody>
            </CCard>
          </CCol>
          <CCol v-if="hasProfit" sm="12" md="3">
            <CCard>
              <CCardBody>
                <AccumulatedProfit />
              </CCardBody>
            </CCard>
          </CCol>
          <CCol sm="12" md="3">
            <CCard>
              <CCardBody>
                <SalesNotCompleted />
              </CCardBody>
            </CCard>
          </CCol>
          <CCol v-if="hasProfit" sm="12" md="3">
            <CCard>
              <CCardBody>
                <PendingProfit />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="12" md="6">
        <CCard>
          <CCardBody>
            <SalesByProduct />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="12" md="6">
        <CCard>
          <CCardBody>
            <DailySales />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="hasChilds" sm="12" md="6">
        <CCard>
          <CCardBody>
            <SalesByVendors />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="hasProfit" sm="12" md="6">
        <CCard>
          <CCardBody>
            <ProfitPaid />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="hasChilds" sm="12" md="6">
        <CCard>
          <CCardBody>
            <TopGainers />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="hasChilds" sm="12" md="6">
        <CCard>
          <CCardBody>
            <TopLosers />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {
  AccumulatedSale,
  AccumulatedProfit,
  SalesNotCompleted,
  PendingProfit,
  SalesByProduct,
  DailySales,
  SalesByVendors,
  ProfitPaid,
  ProgressView,
  TopGainers,
  TopLosers,
  BadgeTimeline,
} from "./dashboard";
import moment from "moment";
import request from "@/utils/request";

export default {
  name: "Dashboard",
  components: {
    AccumulatedSale,
    AccumulatedProfit,
    SalesNotCompleted,
    PendingProfit,
    SalesByProduct,
    DailySales,
    SalesByVendors,
    ProfitPaid,
    TopGainers,
    TopLosers,
    BadgeTimeline,
    ProgressView,
  },
  data() {
    return {
      loading: false,
      filters: {
        dateFrom: null,
        dateTo: null,
        tree: true,
      },
      hasProfit: false,
      hasChilds: false,
    };
  },
  mounted() {
    const notProfit = ['Mayorista', 'Distribuidor'];
    const notChilds = ['Financiero', 'Soporte', 'Distribuidor', 'Subdistribuidor'];
    if( notProfit.includes(this.$store.state.user.role) ){
      this.hasProfit = true;
    }else{
      this.hasProfit = false;
    }
    if( notChilds.includes(this.$store.state.user.role) ){
      this.hasChilds = false;
    }else{
      this.hasChilds = true;
    }
  },
  computed: {
    minDate() {
      return this.filters.dateFrom ? moment(this.filters.dateFrom).format('YYYY-MM-DD') : '';
    },
    maxDate() {
      return moment(new Date()).format('YYYY-MM-DD');
    },
  },
  created() {
    this.filters.dateFrom = moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD');
    this.filters.dateTo = moment(new Date()).format('YYYY-MM-DD');
    this.update();
  },
  methods: {
    update() {
      this.$store.state.dashboard.filters = { ...this.filters };
    },
    async excel(){
      const response = await request({
        url: `/dashboard/getSalesReportExcel?fromLastLiquidation=0&profit=0&tree=${this.filters.tree ? 1 : 0}&dateFrom=${this.filters.dateFrom}&dateTo=${this.filters.dateTo}`,
        method: "get",
        params: {},
        responseType: "blob",
      });
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "ventas.xlsx";
      link.click();
      URL.revokeObjectURL(link.href);
    },
    downloadFile(response, filename) {
      var newBlob = new Blob([response], { type: 'application/xlsx' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = filename + '.xlsx';
      link.click();
      setTimeout(function() {
        window.URL.revokeObjectURL(data);
      }, 100);
    },
  },
};
</script>
