<template>
  <div>
    <div class="text-muted text-center">Top Losers</div>
    <div class="overflow-auto" style="height: 300px">
      <div v-if="data.length > 0 && !isLoading">
        <table class="table table-hover table-striped table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th class="text-left">Nombre de SN</th>
              <th class="text-right">Precio Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td class="text-left">{{ item.user.name }}</td>
              <td class="text-right">{{ item.total ? parseFloat(item.total).toFixed(2) : 0.0 }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2" class="text-left">Total</td>
              <td class="text-right">{{ total }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-if="data.length == 0 && !isLoading" class="text-center mt-4">
        <p>Sin datos para mostrar</p>
      </div>
      <div v-if="isLoading" class="text-center mt-4">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Cargando...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CChartBar } from "@coreui/vue-chartjs";
import Resource from "@/api/resource";

const resource = new Resource("dashboard");

export default {
  name: "TopLosers",
  components: {
    CChartBar,
  },
  data() {
    return {
      isLoading: false,
      loaded: false,
      data: [],
    };
  },
  computed: {
    defaultDatasets() {
      return [{
        label: 'Total',
        backgroundColor: 'rgb(8,125,233,0.5)',
        data: this.data.map(item => item.total),
      }];
    },
    labels() {
      return this.data.map(item => item.user.name);
    },
    total() {
      let total = parseFloat(0);
      for (const key in this.data) {
        if (isNaN(this.data[key].total)) {
          total = parseFloat(total) + parseFloat(0);
        } else {
          total = parseFloat(total) + parseFloat(this.data[key].total);
        }
      }
      return total.toFixed(2);
    }
  },
  watch: {
    "$store.state.dashboard.filters": function (newValue, oldValue) {
      if (newValue) {
        this.getData();
      }
    },
  },
  created() {
    if (!this.loaded) {
      this.getData();
    }
  },
  methods: {
    async getData() {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          const { dateFrom, dateTo, tree } = this.$store.state.dashboard.filters;
          const result = await resource.get(`salesByVendors?limit=10&order=ASC&tree=${tree ? 1 : 0}&dateFrom=${dateFrom}&dateTo=${dateTo}`);
          if (result.status == 200) {
            this.data = result.data;
            this.loaded = true;
          } else {
            console.log(result);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
