export const hierarchy = [
  { label: "Administrador", value: "S" },
  { label: "Vendedor BSmart", value: "S" },
  { label: "Mayorista", value: "S" },
  { label: "Mayorista Vendedor", value: "S" },
  { label: "Distribuidor", value: "S" },
];

export const options = [
  { label: "", value: null },
  { label: "Todas", value: "A" },
  { label: "Propias", value: "O" },
];
