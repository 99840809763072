var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[(_vm.level < _vm.hierarchyList.length - 1)?_c('CCol',{attrs:{"sm":"3"}},[(_vm.level >= 0)?_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CSelect',{attrs:{"label":"Ventas","options":_vm.salesByList,"required":""},model:{value:(_vm.form.sales_by),callback:function ($$v) {_vm.$set(_vm.form, "sales_by", $$v)},expression:"form.sales_by"}})],1)],1):_vm._e(),(_vm.level == 0 && !['A', 'O', null].includes(_vm.form.sales_by))?_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CSelect',{attrs:{"label":_vm.hierarchyList[1].label,"options":_vm.sellerList,"required":""},model:{value:(_vm.form.seller),callback:function ($$v) {_vm.$set(_vm.form, "seller", $$v)},expression:"form.seller"}})],1)],1):_vm._e(),(
        (_vm.level < 1 && !['A', 'O', null].includes(_vm.form.seller)) ||
        (_vm.level == 1 && !['A', 'O', null].includes(_vm.form.sales_by))
      )?_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CSelect',{attrs:{"label":_vm.hierarchyList[2].label,"options":_vm.vendorList,"required":""},model:{value:(_vm.form.vendor),callback:function ($$v) {_vm.$set(_vm.form, "vendor", $$v)},expression:"form.vendor"}})],1)],1):_vm._e(),(
        (_vm.level < 2 && !['A', 'O', null].includes(_vm.form.vendor)) ||
        (_vm.level == 2 && !['A', 'O', null].includes(_vm.form.sales_by))
      )?_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CSelect',{attrs:{"label":_vm.hierarchyList[3].label,"options":_vm.vendor2List,"required":""},model:{value:(_vm.form.vendor2),callback:function ($$v) {_vm.$set(_vm.form, "vendor2", $$v)},expression:"form.vendor2"}})],1)],1):_vm._e(),(
        (_vm.level < 3 && !['A', 'O', null].includes(_vm.form.vendor2)) ||
        (_vm.level == 3 && !['A', 'O', null].includes(_vm.form.sales_by))
      )?_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CSelect',{attrs:{"label":_vm.hierarchyList[4].label,"options":_vm.distributorList,"required":""},model:{value:(_vm.form.distributor),callback:function ($$v) {_vm.$set(_vm.form, "distributor", $$v)},expression:"form.distributor"}})],1)],1):_vm._e()],1):_vm._e(),_c('CCol',{attrs:{"sm":_vm.level < _vm.hierarchyList.length - 1 ? 9 : 12}},[_c('div',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.totalSales - _vm.pendingSales)+" de "+_vm._s(_vm.totalSales)+" ventas completadas en el mes ")]),_c('div',{staticStyle:{"height":"335px"}},[(_vm.data && !_vm.isLoading)?_c('div',[_c('CProgress',{staticClass:"progress-xs mt-4",attrs:{"color":"success","value":_vm.totalSales - _vm.pendingSales,"max":_vm.totalSales}}),_c('CChartLine',{staticClass:"mt-4",staticStyle:{"height":"300px"},attrs:{"datasets":_vm.defaultDatasets,"options":_vm.defaultOptions,"labels":_vm.labels}})],1):_vm._e(),(!_vm.data && !_vm.isLoading)?_c('div',{staticClass:"text-center mt-4"},[_c('p',[_vm._v("Sin datos para mostrar")])]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"text-center mt-4"},[_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Cargando...")])])]):_vm._e()])]),_c('CCol',{attrs:{"sm":"12"}},[(!_vm.isLoading)?_c('CButton',{attrs:{"color":"primary"},on:{"click":_vm.excel}},[_vm._v(" Excel ")]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"text-right"},[_c('CSpinner',{attrs:{"variant":"grow","size":"lg"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }