<template>
  <div>
    <div class="text-muted">Ventas pendientes</div>
    <div class="overflow-auto" style="height: 300px">
      <div v-if="data.length > 0 && !isLoading">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="row small border rounded shadow bg-white mt-2 py-1 ml-0"
          style="width: 100%; cursor: pointer;"
          @click="open(item.id)"
        >
          <div class="col-12">
            <span class="text-muted">Fecha de emisión: </span
            >{{ item.created_at | dateFormat }}
          </div>
          <div class="col-2 text-center">
            <img
              src="https://avataaars.io/"
              class="border rounded-circle"
              alt="avatar"
              height="40px"
            />
          </div>
          <div class="col-7">
            <div class="row">{{ item.name }}</div>
            <div class="row">{{ item.email }}</div>
            <div class="row">Total: ${{ item.total ? parseFloat(item.total).toFixed(2) : 0.0 }}</div>
          </div>
          <div class="col-3 text-center">
            <span class="badge badge-warning p-2">PENDIENTE</span>
          </div>
        </div>
      </div>
      <div v-if="data.length == 0 && !isLoading" class="text-center mt-4">
        <p>Sin datos para mostrar</p>
      </div>
      <div v-if="isLoading" class="text-center mt-4">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Cargando...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resource from "@/api/resource";
import moment from 'moment'

const resource = new Resource("dashboard");

export default {
  name: "PendingSales",
  props: {
    userId: {
      type: String,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      isLoading: false,
      data: [],
    };
  },
   filters: {
    dateFormat: function (value) {
      return value ? moment(value).format("YYYY-MM-DD HH:mm") : "";
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const result = await resource.get("pendingSales"+(this.userId?"?user="+this.userId:""));
        if (result.status == 200) {
          const json = result.data;
          this.data = json.data;
          console.log(this.data)
        } else {
          console.log(result);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    open(id) {
      console.log(id);
      this.$router.push({
        path: "/commerce/complete/" + id,
      });
    },
  },
};
</script>
