<template>
  <CRow>
    <CCol sm="3" v-if="level < hierarchyList.length - 1">
      <CRow v-if="level >= 0">
        <CCol sm="12">
          <CSelect
            label="Ventas"
            v-model="form.sales_by"
            :options="salesByList"
            required
          />
        </CCol>
      </CRow>
      <CRow v-if="level == 0 && !['A', 'O', null].includes(form.sales_by)">
        <CCol sm="12">
          <CSelect
            :label="hierarchyList[1].label"
            v-model="form.seller"
            :options="sellerList"
            required
          />
        </CCol>
      </CRow>
      <CRow
        v-if="
          (level < 1 && !['A', 'O', null].includes(form.seller)) ||
          (level == 1 && !['A', 'O', null].includes(form.sales_by))
        "
      >
        <CCol sm="12">
          <CSelect
            :label="hierarchyList[2].label"
            v-model="form.vendor"
            :options="vendorList"
            required
          />
        </CCol>
      </CRow>
      <CRow
        v-if="
          (level < 2 && !['A', 'O', null].includes(form.vendor)) ||
          (level == 2 && !['A', 'O', null].includes(form.sales_by))
        "
      >
        <CCol sm="12">
          <CSelect
            :label="hierarchyList[3].label"
            v-model="form.vendor2"
            :options="vendor2List"
            required
          />
        </CCol>
      </CRow>
      <CRow
        v-if="
          (level < 3 && !['A', 'O', null].includes(form.vendor2)) ||
          (level == 3 && !['A', 'O', null].includes(form.sales_by))
        "
      >
        <CCol sm="12">
          <CSelect
            :label="hierarchyList[4].label"
            v-model="form.distributor"
            :options="distributorList"
            required
          />
        </CCol>
      </CRow>
    </CCol>
    <CCol :sm="level < hierarchyList.length - 1 ? 9 : 12">
      <div class="text-muted">
        {{ totalSales - pendingSales }} de {{ totalSales }} ventas completadas
        en el mes
      </div>
      <div style="height: 335px">
        <div v-if="data && !isLoading">
          <CProgress
            class="progress-xs mt-4"
            color="success"
            :value="totalSales - pendingSales"
            :max="totalSales"
          />
          <CChartLine
            :datasets="defaultDatasets"
            :options="defaultOptions"
            :labels="labels"
            class="mt-4"
            style="height: 300px"
          />
        </div>
        <div v-if="!data && !isLoading" class="text-center mt-4">
          <p>Sin datos para mostrar</p>
        </div>
        <div v-if="isLoading" class="text-center mt-4">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Cargando...</span>
          </div>
        </div>
      </div>
    </CCol>
    <CCol sm="12">
      <CButton color="primary" @click="excel" v-if="!isLoading">
        Excel
      </CButton>
      <div class="text-right" v-if="isLoading">
        <CSpinner variant="grow" size="lg" />
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { CChartLine } from "@coreui/vue-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils/src";
import Resource from "@/api/resource";
import request from "@/utils/request";

import { hierarchy, options } from "./data";

const resource = new Resource("dashboard");

export default {
  name: "SalesByUser",
  components: {
    CChartLine,
  },
  data() {
    return {
      isLoading: false,
      data: null,
      form: {
        sales_by: null,
        seller: null,
        vendor: null,
        vendor2: null,
        distributor: null,
      },
      role: null,
      level: null,
      sellers: [],
      vendors: [],
      vendors2: [],
      distributors: [],
      delayTimer: null,
    };
  },
  computed: {
    labels() {
      return this.data ? this.data.salesMonth.map((item) => item.sale_day) : [];
    },
    pendingSales() {
      const total = this.data
        ? this.data.allSalesMonth.filter(
            (item) => item.paymentCode == null && item.paymentCode == ""
          ).length
        : 0;
      return total;
    },
    totalSales() {
      const total = this.data ? this.data.allSalesMonth.length : 0;
      return total;
    },
    maxValue() {
      let value = 50;
      if (this.data) {
        let tmp = 0;
        this.data.salesMonth.forEach((item) => {
          if (item.total > tmp) {
            tmp = item.total;
          }
        });
        if (tmp > 0) {
          while (value < tmp) {
            value += 50;
          }
        }
      }
      return value;
    },
    defaultDatasets() {
      const brandInfo = getStyle("info") || "#20a8d8";

      return [
        {
          label: "Total",
          backgroundColor: hexToRgba(brandInfo, 10),
          borderColor: brandInfo,
          pointHoverBackgroundColor: brandInfo,
          borderWidth: 2,
          data: this.data
            ? this.data.salesMonth.map((item) =>
                parseFloat(item.total).toFixed(2)
              )
            : [],
        },
      ];
    },
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,
                stepSize: Math.ceil(this.maxValue / 5),
                max: this.maxValue,
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
          },
        },
      };
    },
    hierarchyList() {
      return hierarchy;
    },
    salesByList() {
      let list = [];
      if (this.level >= 0 && this.level < hierarchy.length) {
        const nextRole = hierarchy[this.level + 1];
        if (this.level == 0) {
          list = options.filter(
            (item) => item.value != "O" && item.value != null
          );
        } else {
          list = options.filter(
            (item) => item.value != "A" && item.value != null
          );
        }
        list = [...list, { label: nextRole.label, value: "S" }];
      }
      return list;
    },
    sellerList() {
      let list = options.filter((item) => item.value != "O");
      if (this.form.sales_by == "S") {
        list = list.filter((item) => item.value != null);
      }
      return [...list, hierarchy[2], ...this.sellers];
    },
    vendorList() {
      let list = options.filter((item) => item.value != "O");
      if (
        (this.level < 1 && this.form.seller == "S") ||
        (this.level == 1 && this.form.sales_by == "S")
      ) {
        list = list.filter((item) => item.value != null);
      }
      return [...list, hierarchy[3], ...this.vendors];
    },
    vendor2List() {
      let list = options.filter((item) => item.value != "O");
      if (
        (this.level < 2 && this.form.vendor == "S") ||
        (this.level == 2 && this.form.sales_by == "S")
      ) {
        list = list.filter((item) => item.value != null);
      }
      return [...list, hierarchy[4], ...this.vendors2];
    },
    distributorList() {
      let list = options.filter((item) => item.value != "O");
      if (
        (this.level < 3 && this.form.vendor2 == "S") ||
        (this.level == 3 && this.form.sales_by == "S")
      ) {
        list = list.filter((item) => item.value != null);
      }
      return [...list, ...this.distributors];
    },
  },
  watch: {
    "form.sales_by": function (newValue, oldValue) {
      if (newValue != oldValue) {
        if (oldValue == "S") {
          this.form.seller = null;
          this.form.vendor = null;
          this.form.vendor2 = null;
          this.form.distributor = null;
        }

        if (newValue == "S") {
          this.getDirectSons({
            role: hierarchy[this.level + 1].label,
            index: this.level + 1,
          });
        }

        this.getData();
      }
    },
    "form.seller": function (newValue, oldValue) {
      if (newValue != oldValue) {
        if (oldValue == "S") {
          this.form.vendor = null;
          this.form.vendor2 = null;
          this.form.distributor = null;
        }

        if (newValue) {
          let params = {};
          if (!isNaN(newValue)) params.parent_id = newValue;
          if (newValue == "S" || !isNaN(newValue)) {
            params = { role: hierarchy[2].label, index: 2 };
            this.getDirectSons(params);
          }
        }

        this.getData();
      }
    },
    "form.vendor": function (newValue, oldValue) {
      if (newValue != oldValue) {
        if (oldValue == "S") {
          this.form.vendor2 = null;
          this.form.distributor = null;
        }

        if (newValue) {
          let params = {};
          if (!isNaN(newValue)) params.parent_id = newValue;
          if (newValue == "S" || !isNaN(newValue)) {
            params = { role: hierarchy[3].label, index: 3 };
            this.getDirectSons(params);
          }
        }

        this.getData();
      }
    },
    "form.vendor2": function (newValue, oldValue) {
      if (newValue != oldValue) {
        if (oldValue == "S") {
          this.form.distributor = null;
        }

        if (newValue) {
          let params = {};
          if (!isNaN(newValue)) params.parent_id = newValue;
          if (newValue == "S" || !isNaN(newValue)) {
            params = { role: hierarchy[4].label, index: 4 };
            this.getDirectSons(params);
          }
        }

        this.getData();
      }
    },
    "form.distributor": function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.getData();
      }
    },
  },
  created() {
    this.role = this.$store.state.user.role;
    this.level = hierarchy.findIndex((item) => item.label == this.role);
    // console.log(`role: ${this.role} | level: ${this.level}`);
    if (this.level == 0) {
      this.form.sales_by = "A";
    } else {
      this.form.sales_by = "O";
    }
    this.getData();
  },
  methods: {
    async getData() {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(async () => {
        this.isLoading = true;
        try {
          const params = new URLSearchParams(this.form).toString();
          const result = await resource.get(`salesByUser?${params}`);
          if (result.status == 200) {
            const json = result.data;
            this.data = json.data;
          } else {
            console.log(result);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      }, 500);
    },
    async getDirectSons(params) {
      try {
        const query = new URLSearchParams(params).toString();
        const result = await resource.get(`directSons?${query}`);
        if (result.status == 200) {
          const { data } = result.data;
          switch (params.index) {
            case 1:
              this.sellers = data;
              break;
            case 2:
              this.vendors = data;
              break;
            case 3:
              this.vendors2 = data;
              break;
            case 4:
              this.distributors = data;
              break;
          }
        } else {
          console.log(result);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async excel() {
      const response = await request({
        url: `/dashboard/salesByUser`,
        method: "get",
        params: { export: true, ...this.form },
        responseType: "blob",
      });
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "ventas.xlsx";
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
};
</script>
