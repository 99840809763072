<template>
  <div>
    <div class="text-muted">Top productos vendidos</div>
    <div style="height: 335px">
      <CChartDoughnut
        :datasets="defaultDatasets"
        :labels="labels"
        class="mt-4"
        v-if="data.length > 0 && !isLoading"
      />
      <div v-if="data.length == 0 && !isLoading" class="text-center mt-4">
        <p>Sin datos para mostrar</p>
      </div>
      <div v-if="isLoading" class="text-center mt-4">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Cargando...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CChartDoughnut } from "@coreui/vue-chartjs";
import Resource from "@/api/resource";

const resource = new Resource("dashboard");

export default {
  name: "TopSellingProducts",
  components: {
    CChartDoughnut,
  },
  data() {
    return {
      isLoading: false,
      data: [],
    };
  },
  computed: {
    defaultDatasets() {
      return [
        {
          backgroundColor: [
            "#1e88e5",
            "#3d5afe",
            "#00c853",
            "#ffab00",
            "#ff3d00",
          ],
          data: this.data.map(item => item.total),
        },
      ];
    },
    labels() {
      return this.data.map(item => item.ItemName);
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const result = await resource.get("topSellingProducts");
        if (result.status == 200) {
          const json = result.data;
          this.data = json.data;
        } else {
          console.log(result);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
