<template>
  <div>
    <div class="text-muted">Top vendedores</div>
    <div class="overflow-auto" style="height: 300px">
      <div v-if="data.length > 0 && !isLoading">
        <table class="table table-hover table-striped">
          <tbody>
            <tr v-for="(item, index) in data" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ item.user.name }}</td>
              <td>
                {{ item.total ? parseFloat(item.total).toFixed(2) : 0.0 }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="data.length == 0 && !isLoading" class="text-center mt-4">
        <p>Sin datos para mostrar</p>
      </div>
      <div v-if="isLoading" class="text-center mt-4">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Cargando...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resource from "@/api/resource";

const resource = new Resource("dashboard");

export default {
  name: "TopSalesByUser",
  components: {},
  data() {
    return {
      isLoading: false,
      data: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const result = await resource.get("topSalesByUser");
        if (result.status == 200) {
          const json = result.data;
          this.data = json.data;
        } else {
          console.log(result);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
